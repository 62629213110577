.text-strike {
    text-decoration: line-through;
}

.show-in-screenshot {
    visibility: hidden;
}

.screenshot {
    min-height: 0!important;
}

.screenshot .hide-in-screenshot {
    visibility: hidden;
    height: 0;
}

.screenshot .show-in-screenshot {
    visibility: visible;
}

.screenshot .sc-no-flex-fill {
    flex:0 0 auto!important;
}