.zoom-in-out-animation {
    animation: zoom-in-out-animation 1s ease infinite;
}

@keyframes zoom-in-out-animation {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.2, 1.2);
    }
    100% {
        transform: scale(1, 1);
    }
}

.slide-in-animation {
    animation: slide-in-out-animation 1s ease-out;
}

@keyframes slide-in-out-animation {
    0% {
        transform: translateY(60px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


