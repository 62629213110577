.App {
  min-height: 100vh;
}

.safe-top-area {
  position: sticky;
  top:0;
  height: 34px;
  background-color: rgb(239, 239, 239);
}

.color-px-blue {
  color: rgb(31, 116, 223);
}

.bg-px-blue {
  background-color: rgb(31, 116, 223);
}

/* Past colors #124A8B */
.bg-primary {
  background-color: rgb(31, 116, 223) !important;
}

.right-0 {
  right: 0;
}

.hover-shadow, .cursor-pointer {
  cursor: pointer;
}

.hover-shadow:hover{
  box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important;
}

.header-style1 {
  border-left: 4px solid #1167b1;
  background-color: rgba(17,103,177,0.1);
}

.no-text-decor{
  text-decoration: none;
  color: inherit;
  cursor: pointer !important;
}

.no-text-decor:hover{
  text-decoration: none;
  color: inherit;
}

.img-object-fit-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-object-fit-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.hw_64 {
  height: 64px;
  width: 64px;
}

.rounded-right-0 {
  border-top-right-radius:0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-control:-webkit-autofill {
  -webkit-background-clip: text;
}

.fs-12 {
  font-size: 12px;
}

.fs-1_6vw {
  font-size: 1.6vw;
}

.fs-2_5vw {
  font-size: 2.5vw;
}

.fs_heading {
  transition: 400ms;
  font-size: calc(1.375rem + (10) * ((100vw - 320px) / (1920 - 320)));
}

.fs_heading2 {
  transition: 400ms;
  font-size: calc(1.2rem + (10) * ((100vw - 320px) / (1920 - 320)));
}

.fs_75c_10 {
  transition: 400ms;
  font-size: calc(0.75rem + (10) * ((100vw - 320px) / (1920 - 320)));
}

.fs_75c_4 {
  transition: 400ms;
  font-size: calc(0.75rem + (4) * ((100vw - 320px) / (1920 - 320)));
}

.fs_75c_3 {
  transition: 400ms;
  font-size: calc(0.75rem + (3) * ((100vw - 320px) / (1920 - 320)));
}

.fs_45c_4 {
  transition: 400ms;
  font-size: calc(0.45rem + (4) * ((100vw - 320px) / (1920 - 320)));
}

.p-1_5vw {
  padding: 1.5vw;
}

.pt-5p {
  padding-top: 5%;
}

.pb-10p {
  padding-bottom: 10%;
}

.bg-light-green {
  background-color: rgba(197, 229, 51, 1);
}

.bg-light-green2 {
  background-color: rgb(44, 147, 3);
}

.text-light-green2 {
  color: rgb(44, 147, 3);
}

.bg-light-primary {
  background-color: rgba(18, 74, 139, 0.2);
}

.bg-light-success {
  background-color:rgba(25,135,84, 0.2);
}

.bg-light-danger {
  background-color:rgba(220,53,69, 0.2);
}

.bg-light-yellow {
  background-color: rgba(255,245,56,0.4);
}

.bg-light-blue {
  background-color: rgba(125, 215, 231, 1);
}

.bg-light-blue2 {
  background: rgb(243, 245, 253);
}

.bg-light-blue3 {
  background: rgb(240, 249, 255);
}

.bg-light-blue4 {
  background: rgb(245, 245, 255);
}

input, select {
  box-shadow: none !important;
}

input:invalid, .validate_input:invalid {
  border: 1px solid red !important;
}

.show_on_small_devices {
  display: none !important;
}

.hide_on_small_devices {
  display: block !important;
}

.bangers-font {
  font-family: 'Bangers', cursive;
  letter-spacing: 1px;
}

.georgia-font {
  font-family: Palatino, "URW Palladio L", Georgia, Times, "Times New Roman", serif;
}

.monaco-font {
  font-family: Monaco, "Lucida Console", serif;
}

input[type="invalid"] {
  border: red;
}

.rounded-pm-1 {
  border-radius: 28px;
}

.svg-blue {
  fill: rgb(31, 116, 223);
}

.svg-grey {
  fill: rgb(234, 234, 234);
}

.svg-green {
  fill: rgb(25,135,84);
}

@media only screen and (max-width : 768px) {
  .show_on_small_devices {
    display: block !important;
  }

  .hide_on_small_devices {
    display: none !important;
  }

  .rounded-pm-1-sd {
    border-radius: 28px;
    overflow: hidden;
  }
  .m-2-sd {
    margin:.5rem!important
  }
}
